import React, { Component } from 'react';

class App extends Component {
  state = {
    service: 1200,
    serviceper: 50,
    product: 150,
    productper: 10,
    oldweek: 615,
    newweek: 867,
    rent: 300,
    changeval: this.changeval.bind( this )
  }

  changeval( setting, val ){
    val = parseInt(val)
    let newstate = {}
    newstate[setting] = val

    switch ( setting ){
      case 'service':
      console.log('service')
      newstate['oldweek'] = ((val * this.state.serviceper/100) + (this.state.product * this.state.productper/100))
      newstate['newweek'] = (val + this.state.product/2 - 108 - this.state.rent)
      break
      case 'serviceper':
      newstate['oldweek'] = ((this.state.service * val/100) + (this.state.product * this.state.productper/100))
      break
      case 'product':
      newstate['oldweek'] = ((this.state.service * this.state.serviceper/100) + (val * this.state.productper/100))
      newstate['newweek'] = (this.state.service + val/2 - 108 - this.state.rent)
      break
      case 'productper':
      newstate['oldweek'] = ((this.state.service * this.state.serviceper/100) + (this.state.product * val/100))
      break
      case 'rent':
      if (!isNaN(val)){
        newstate['newweek'] = (this.state.service + this.state.product/2 - 108 - val)
      }
      else{
        newstate['newweek'] = (this.state.service + this.state.product/2 - 108 - 300)
      }
      
      break
    }
    this.setState( newstate )
  }
  
  render( ) {
    return (
      <div>
      <div className='incomecalc row'>
        <div>
          <h1>Income Calculator</h1>
          <p>Plug in your own numbers to see how your current income compares to what you could make working for yourself.</p>
          
          <div className='row'>
            <Slider label="Estimate Your Weekly Service Revenue" name="service" min="0" max="3000" step="100" pre="$" val={ this.state.service } callback={ this.state.changeval } />

            <Slider label="What percent of service revenue do you earn?" name="serviceper" min="0" max="100" post="%" val={ this.state.serviceper } callback={ this.changeval.bind(this) } />
          </div>
          <div className='row'>
            <Slider label="Estimated Weekly Hair Product Sales" name="product" min="0" max="2000" step="10" pre="$" val={ this.state.product} callback={ this.changeval.bind(this)} />

            <Slider label="What percent of product sales do you earn?" name="productper" min="0" max="100" post="%" val={ this.state.productper } callback={ this.changeval.bind(this) } />
          </div>

          <Rent options={[150,250,350]} rent={ this.state.rent } callback={ this.changeval.bind(this)} />

          <div className="row">
            <OldIncome props={ this.state }  />
            <NewIncome props={ this.state }  />
          </div>
          
        </div>
        <Graph old={ this.state.oldweek } new={ this.state.newweek }/>
        <h6 className='disclaimer'>Please Note: All calculations are to be used for direct comparisons and estimating purposes only.</h6>
        </div>
      </div>
    );
  }
}

const Graph = (props) => {
  let oldh = ((props.old / props.new) * 100)+"%"
  let newh = "100%"
  if ( props.old > props.new ){
    newh = ((props.new / props.old) * 100)+"%"
    oldh = "100%"
  }
  return(
    <div className='graph'>
    <div>
      <div style={{height: oldh }} className="old">
      {toMoney(props.old * 52)}<br/>Current Income

      </div>
      <div style={{height: newh }} className="new">
      {toMoney(props.new * 52)}<br/>Studio Owner Income
      </div>
      </div>
      <div className='results'>
      <div className='row'><span>Weekly Difference:</span><span>{toMoney( props.new - props.old )}</span></div>
      <div className='row'><span>Yearly Difference:</span><span>{toMoney( (props.new - props.old) * 52)}</span></div>
      <div className='row'><span>Percent Difference:</span><span>{ (((props.new-props.old)/props.old)*100).toFixed(0) }%</span></div>
      </div>
    </div>
  )
}

const OldIncome = ( props ) => {
  props = props.props
  const prodprof = parseInt(props.product * props.productper/100)
  const servprof = parseInt(props.service * props.serviceper/100)
  

  return(
    <div className="income">
      <h5>Your Current Income</h5>
      <div className='row'><div>Total Service Revenue</div><div> ${ props.service }</div></div>
      <div className='row'><div>Salon Owner Retains</div><div> ${ (props.service * ((100 - props.serviceper)/100)).toFixed(0) }</div></div>
      <div className='row'><div>Service Profit (Yours)</div><div> ${ servprof }</div></div>
      <div className='row'><div>Product Sales</div><div> ${ props.product }</div></div>
      <div className='row'><div>Product Profit (Yours)</div><div> ${ prodprof }</div></div>
      <div className='row'><div>Phone / Insurance / Misc.</div><div> $0</div></div>
      <div className='incomebox old'>
        <h6>Your Estimated Current Income</h6>
        <div className='row'><div>{ toMoney(props.oldweek) }</div><div> per week</div></div>
        <div className='row'><div>{ toMoney((props.oldweek) * 4) }</div><div> per month</div></div>
        <div className='row'><div>{ toMoney((props.oldweek) * 52) }</div><div> per year</div></div>
      </div>
    </div>
    )
}

const NewIncome = ( props ) => {
  props = props.props
  const servprof = parseInt(props.service - props.rent)
  const prodprof = props.product / 2

  return(
    <div className="income">
      <h5>Your New Income</h5>
      <div className='row'><div>Total Service Revenue</div><div> ${ props.service }</div></div>
      <div className='row'><div>My Salon Suite - Weekly Rent</div><div> ${ props.rent }</div></div>
      <div className='row'><div>Service Profit (Yours)</div><div> ${ servprof }</div></div>
      <div className='row'><div>Product Sales</div><div> ${ props.product }</div></div>
      <div className='row'><div>Product Profit (Yours)</div><div> ${ prodprof }</div></div>
      <div className='row'><div>Phone / Insurance / Misc.</div><div> $108</div></div>
      <div className="incomebox new">
        <h6>Your Estimated Potential Income</h6>
        <div className='row'><div>{ toMoney(props.newweek) }</div><div> per week</div></div>
        <div className='row'><div>{ toMoney((props.newweek) * 4) }</div><div> per month</div></div>
        <div className='row'><div>{ toMoney((props.newweek) * 52) }</div><div> per year</div></div>
      </div>
    </div>
    )
}

class Rent extends Component{
  constructor( props ){
    super( props )
    this.handleinput = handleinput.bind( this )
  }

  render(){
    return (
      <div className='row right'>
      <div className='container'>
      <label>My Salon Suite - Weekly Rent: </label>
        <span className='dyn-output'>$<input type='text' name='rent' size="5" value={ (!isNaN(this.props.rent)) ? this.props.rent : 300 } onChange={ this.handleinput }/></span>
      </div>
      </div>
    )
  }
}

class Slider extends Component{
  constructor( props ){
    super( props )
    this.handleinput = handleinput.bind( this )
  }
  
  render(){
    return(
      <div className="container">
      <label>{ this.props.label }</label>
      <input
        onChange={ this.handleinput }
        type='range' step={ this.props.step }
        min={ this.props.min } max={this.props.max}
        value={ this.props.val }
        className='slider'
        name={ this.props.name } />
      <span className="dyn-output">{ this.props.pre }<input type="text" size="6" value={this.props.val} onChange={ this.handleinput } name={ this.props.name }/>{ this.props.post }</span>
      
      </div>
    )
  }
}

function handleinput(e) {
  this.props.callback( e.target.name, e.target.value) 
}

function toMoney( n ){
  return ("$"+(( n ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')))
}


export default App;
